import {createSlice} from '@reduxjs/toolkit';

interface State {
  orderData: any[];
  showRequestMobileForm: boolean;
}
export const initialState: State = {
  orderData: [],
  showRequestMobileForm: false,
};

export const addRequestSlice = createSlice({
  name: 'addRequest',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = state.orderData.findIndex(
        item => item.id === newItem.id,
      );
      if (existingItemIndex !== -1) {
        // If the item already exists, update its count
        state.orderData[existingItemIndex].quntum +=
          newItem.quantityIncremental;
      } else {
        // If the item doesn't exist, add it to the list
        state.orderData.unshift({
          ...newItem,
          quntum: newItem.minimumQuantity > newItem.quantityIncremental ? newItem.minimumQuantity:newItem.quantityIncremental,
        });
      }
    },
    // adjustItemQuantity: (state, action) => {
    //   const newItem = action.payload;
    //   const existingItemIndex = state.orderData.findIndex(
    //     item => item.id === newItem.id,
    //   );
    
    //   if (existingItemIndex !== -1) {
    //     state.orderData[existingItemIndex].quntum = newItem.quntum;
    
    //     if (state.orderData[existingItemIndex].quntum <= 0 || state.orderData[existingItemIndex].quntum < newItem.minimumQuantity) {
    //     // if (state.orderData[existingItemIndex].quntum <= 0) {
          
    //       state.orderData.splice(existingItemIndex, 1);
    //     }
    //   } else {
    //     state.orderData.unshift({
    //       ...newItem,
    //       quntum: newItem.quantityIncremental - newItem.quantityDecremental,
    //     });
    //   }
    // }, 
adjustItemQuantity: (state, action) => {
  const newItem = action.payload;
  const existingItemIndex = state.orderData.findIndex(
    item => item.id === newItem.id,
  );

  if (existingItemIndex !== -1) {
    state.orderData[existingItemIndex].quntum = newItem.quntum;

    if (newItem.preferredPrice !== undefined) {
      state.orderData[existingItemIndex].preferredPrice = newItem.preferredPrice;
    }

    if (
      state.orderData[existingItemIndex].quntum < 0  ||
      state.orderData[existingItemIndex].quntum < newItem.minimumQuantity
    ) {
      state.orderData.splice(existingItemIndex, 1);
    }
  } else {
    state.orderData.unshift({
      ...newItem,
      quntum: newItem.quantityIncremental - newItem.quantityDecremental,
    });
  }
},
addItemQuantity: (state, action) => {
  const newItem = action.payload;
  const existingItemIndex = state.orderData.findIndex(
    item => item.id === newItem.id,
  );

  if (existingItemIndex !== -1) {
    state.orderData[existingItemIndex].quntum = newItem.quntum;

    if (newItem.preferredPrice !== undefined) {
      state.orderData[existingItemIndex].preferredPrice = newItem.preferredPrice;
    }

    if (
      state.orderData[existingItemIndex].quntum < 0 
      // state.orderData[existingItemIndex].quntum < newItem.minimumQuantity
    ) {
      state.orderData.splice(existingItemIndex, 1);
    }
  } else {
    state.orderData.unshift({
      ...newItem,
      quntum: newItem.quantityIncremental - newItem.quantityDecremental,
    });
  }
},

    removeItem: (state, action) => {
      const newItem = action.payload;

      const existingItemIndex = state.orderData.findIndex(
        item => item.id === newItem.id,
      );

      if (existingItemIndex !== -1) {
        state.orderData[existingItemIndex].quntum -=
          newItem.quantityIncremental;

        if (state.orderData[existingItemIndex].quntum === 0 || state.orderData[existingItemIndex].quntum < newItem.minimumQuantity) {
        // if (state.orderData[existingItemIndex].quntum === 0) {
          // If the item's count becomes 0, remove it from the list
          state.orderData.splice(existingItemIndex, 1);
        }
      }
    },
    clearOrder: state => {
      state.orderData = [];
    },
    deleteItem: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = state.orderData.findIndex(
        item => item.id === newItem.id,
      );
      if (existingItemIndex !== -1) {
        state.orderData.splice(existingItemIndex, 1);
      }
    },
    setShowRequestMobileForm: (state, action) => {
      state.showRequestMobileForm = action.payload;
    },
  },
});

export const {addItem, removeItem,deleteItem, clearOrder,adjustItemQuantity, addItemQuantity,setShowRequestMobileForm} =
  addRequestSlice.actions;

export default addRequestSlice.reducer;
